import React from "react";
import "../styles/sticky.css";

const Sticky = () => {
  return (
    <div>
      <section className="sticky-section">
        <div class="left">
          <div class="inner sticky">
            <div
              class="bg"
              
            />
            <img src={require("../assets/images/franchise1.png")} />
          </div>
        </div>
        <div class="right">
          <div class="inner">
          <div className="flex flex-col text-black w-[40vw] mx-auto pb-10">
          <h2 className="text-4xl font-bold mb-4 text-center pt-16">
            Franchise 1
          </h2>
          
        </div>
        <div className="flex flex-col gap-9 items-center">
          <div class="relative grid h-[15rem] w-full max-w-[32rem] flex-col justify-center overflow-hidden rounded-xl text-center">
            <img
              className="absolute"
              src={require("../assets/images/franchise1.png")}
              alt="abc"
            />
            <div class="relative p-6 px-6 py-14 md:px-12">
              <h1 class="mb-2 block text-4xl leading-[1.5] text-white ">
                Lorem Ipsum
              </h1>
              <p className="text-white">
                Lorem ipsum dolor sit amet consectetur. Proin lectus eu semper
                ut bibendum id phasellus rhoncus pretium.
              </p>
            </div>
          </div>

          <div class="relative grid h-[15rem] w-full max-w-[32rem] flex-col justify-center overflow-hidden rounded-xl text-center">
            <img
              className="absolute"
              src={require("../assets/images/franchise1.png")}
              alt="abc"
            />
            <div class="relative p-6 px-6 py-14 md:px-12">
              <h1 class="mb-2 block text-4xl leading-[1.5] text-white ">
                Lorem Ipsum
              </h1>
              <p className="text-white">
                Lorem ipsum dolor sit amet consectetur. Proin lectus eu semper
                ut bibendum id phasellus rhoncus pretium.
              </p>
            </div>
          </div>

          <div class="relative grid h-[15rem] w-full max-w-[32rem] flex-col justify-center overflow-hidden rounded-xl text-center">
            <img
              className="absolute"
              src={require("../assets/images/franchise1.png")}
              alt="abc"
            />
            <div class="relative p-6 px-6 py-14 md:px-12">
              <h1 class="mb-2 block text-4xl leading-[1.5] text-white ">
                Lorem Ipsum
              </h1>
              <p className="text-white">
                Lorem ipsum dolor sit amet consectetur. Proin lectus eu semper
                ut bibendum id phasellus rhoncus pretium.
              </p>
            </div>
          </div>

          <div class="relative grid h-[15rem] w-full max-w-[32rem] flex-col justify-center overflow-hidden rounded-xl text-center">
            <img
              className="absolute"
              src={require("../assets/images/franchise1.png")}
              alt="abc"
            />
            <div class="relative p-6 px-6 py-14 md:px-12">
              <h1 class="mb-2 block text-4xl leading-[1.5] text-white ">
                Lorem Ipsum
              </h1>
              <p className="text-white">
                Lorem ipsum dolor sit amet consectetur. Proin lectus eu semper
                ut bibendum id phasellus rhoncus pretium.
              </p>
            </div>
          </div>
          
        </div>
      </div>
          
        </div>
      </section>
      <section className="sticky-section">
        <div class="left">
          <div class="inner sticky">
            <div
              class="bg"
              style={{
                backgroundImage:
                  'url("https://cdn.pixabay.com/photo/2012/06/19/10/32/owl-50267_960_720.jpg")',
              }}
            />
          </div>
        </div>
        <div class="right">
          <div class="inner">
            <h2 class="text-center">SECTION 2</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa nam
              reiciendis unde magni eligendi excepturi maxime nulla adipisci,
              veniam officiis, explicabo commodi laboriosam blanditiis
              repellendus quidem debitis ab! Aliquid, facere. Lorem ipsum dolor
              sit amet, consectetur adipisicing elit. Ipsa nam reiciendis unde
              magni eligendi excepturi maxime nulla adipisci, veniam officiis,
              explicabo commodi laboriosam blanditiis repellendus quidem debitis
              ab! Aliquid, facere. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Ipsa nam reiciendis unde magni eligendi
              excepturi maxime nulla adipisci, veniam officiis, explicabo
              commodi laboriosam blanditiis repellendus quidem debitis ab!
              Aliquid, facere. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Ipsa nam reiciendis unde magni eligendi
              excepturi maxime nulla adipisci, veniam officiis, explicabo
              commodi laboriosam blanditiis repellendus quidem debitis ab!
              Aliquid, facere.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa nam
              reiciendis unde magni eligendi excepturi maxime nulla adipisci,
              veniam officiis, explicabo commodi laboriosam blanditiis
              repellendus quidem debitis ab! Aliquid, facere. Lorem ipsum dolor
              sit amet, consectetur adipisicing elit. Ipsa nam reiciendis unde
              magni eligendi excepturi maxime nulla adipisci, veniam officiis,
              explicabo commodi laboriosam blanditiis repellendus quidem debitis
              ab! Aliquid, facere. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Ipsa nam reiciendis unde magni eligendi
              excepturi maxime nulla adipisci, veniam officiis, explicabo
              commodi laboriosam blanditiis repellendus quidem debitis ab!
              Aliquid, facere. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Ipsa nam reiciendis unde magni eligendi
              excepturi maxime nulla adipisci, veniam officiis, explicabo
              commodi laboriosam blanditiis repellendus quidem debitis ab!
              Aliquid, facere.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa nam
              reiciendis unde magni eligendi excepturi maxime nulla adipisci,
              veniam officiis, explicabo commodi laboriosam blanditiis
              repellendus quidem debitis ab! Aliquid, facere. Lorem ipsum dolor
              sit amet, consectetur adipisicing elit. Ipsa nam reiciendis unde
              magni eligendi excepturi maxime nulla adipisci, veniam officiis,
              explicabo commodi laboriosam blanditiis repellendus quidem debitis
              ab! Aliquid, facere. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Ipsa nam reiciendis unde magni eligendi
              excepturi maxime nulla adipisci, veniam officiis, explicabo
              commodi laboriosam blanditiis repellendus quidem debitis ab!
              Aliquid, facere. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Ipsa nam reiciendis unde magni eligendi
              excepturi maxime nulla adipisci, veniam officiis, explicabo
              commodi laboriosam blanditiis repellendus quidem debitis ab!
              Aliquid, facere.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa nam
              reiciendis unde magni eligendi excepturi maxime nulla adipisci,
              veniam officiis, explicabo commodi laboriosam blanditiis
              repellendus quidem debitis ab! Aliquid, facere. Lorem ipsum dolor
              sit amet, consectetur adipisicing elit. Ipsa nam reiciendis unde
              magni eligendi excepturi maxime nulla adipisci, veniam officiis,
              explicabo commodi laboriosam blanditiis repellendus quidem debitis
              ab! Aliquid, facere. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Ipsa nam reiciendis unde magni eligendi
              excepturi maxime nulla adipisci, veniam officiis, explicabo
              commodi laboriosam blanditiis repellendus quidem debitis ab!
              Aliquid, facere. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Ipsa nam reiciendis unde magni eligendi
              excepturi maxime nulla adipisci, veniam officiis, explicabo
              commodi laboriosam blanditiis repellendus quidem debitis ab!
              Aliquid, facere.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa nam
              reiciendis unde magni eligendi excepturi maxime nulla adipisci,
              veniam officiis, explicabo commodi laboriosam blanditiis
              repellendus quidem debitis ab! Aliquid, facere. Lorem ipsum dolor
              sit amet, consectetur adipisicing elit. Ipsa nam reiciendis unde
              magni eligendi excepturi maxime nulla adipisci, veniam officiis,
              explicabo commodi laboriosam blanditiis repellendus quidem debitis
              ab! Aliquid, facere. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Ipsa nam reiciendis unde magni eligendi
              excepturi maxime nulla adipisci, veniam officiis, explicabo
              commodi laboriosam blanditiis repellendus quidem debitis ab!
              Aliquid, facere. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Ipsa nam reiciendis unde magni eligendi
              excepturi maxime nulla adipisci, veniam officiis, explicabo
              commodi laboriosam blanditiis repellendus quidem debitis ab!
              Aliquid, facere.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa nam
              reiciendis unde magni eligendi excepturi maxime nulla adipisci,
              veniam officiis, explicabo commodi laboriosam blanditiis
              repellendus quidem debitis ab! Aliquid, facere. Lorem ipsum dolor
              sit amet, consectetur adipisicing elit. Ipsa nam reiciendis unde
              magni eligendi excepturi maxime nulla adipisci, veniam officiis,
              explicabo commodi laboriosam blanditiis repellendus quidem debitis
              ab! Aliquid, facere. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Ipsa nam reiciendis unde magni eligendi
              excepturi maxime nulla adipisci, veniam officiis, explicabo
              commodi laboriosam blanditiis repellendus quidem debitis ab!
              Aliquid, facere. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Ipsa nam reiciendis unde magni eligendi
              excepturi maxime nulla adipisci, veniam officiis, explicabo
              commodi laboriosam blanditiis repellendus quidem debitis ab!
              Aliquid, facere.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa nam
              reiciendis unde magni eligendi excepturi maxime nulla adipisci,
              veniam officiis, explicabo commodi laboriosam blanditiis
              repellendus quidem debitis ab! Aliquid, facere. Lorem ipsum dolor
              sit amet, consectetur adipisicing elit. Ipsa nam reiciendis unde
              magni eligendi excepturi maxime nulla adipisci, veniam officiis,
              explicabo commodi laboriosam blanditiis repellendus quidem debitis
              ab! Aliquid, facere. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Ipsa nam reiciendis unde magni eligendi
              excepturi maxime nulla adipisci, veniam officiis, explicabo
              commodi laboriosam blanditiis repellendus quidem debitis ab!
              Aliquid, facere. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Ipsa nam reiciendis unde magni eligendi
              excepturi maxime nulla adipisci, veniam officiis, explicabo
              commodi laboriosam blanditiis repellendus quidem debitis ab!
              Aliquid, facere.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa nam
              reiciendis unde magni eligendi excepturi maxime nulla adipisci,
              veniam officiis, explicabo commodi laboriosam blanditiis
              repellendus quidem debitis ab! Aliquid, facere. Lorem ipsum dolor
              sit amet, consectetur adipisicing elit. Ipsa nam reiciendis unde
              magni eligendi excepturi maxime nulla adipisci, veniam officiis,
              explicabo commodi laboriosam blanditiis repellendus quidem debitis
              ab! Aliquid, facere. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Ipsa nam reiciendis unde magni eligendi
              excepturi maxime nulla adipisci, veniam officiis, explicabo
              commodi laboriosam blanditiis repellendus quidem debitis ab!
              Aliquid, facere. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Ipsa nam reiciendis unde magni eligendi
              excepturi maxime nulla adipisci, veniam officiis, explicabo
              commodi laboriosam blanditiis repellendus quidem debitis ab!
              Aliquid, facere.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa nam
              reiciendis unde magni eligendi excepturi maxime nulla adipisci,
              veniam officiis, explicabo commodi laboriosam blanditiis
              repellendus quidem debitis ab! Aliquid, facere. Lorem ipsum dolor
              sit amet, consectetur adipisicing elit. Ipsa nam reiciendis unde
              magni eligendi excepturi maxime nulla adipisci, veniam officiis,
              explicabo commodi laboriosam blanditiis repellendus quidem debitis
              ab! Aliquid, facere. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Ipsa nam reiciendis unde magni eligendi
              excepturi maxime nulla adipisci, veniam officiis, explicabo
              commodi laboriosam blanditiis repellendus quidem debitis ab!
              Aliquid, facere. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Ipsa nam reiciendis unde magni eligendi
              excepturi maxime nulla adipisci, veniam officiis, explicabo
              commodi laboriosam blanditiis repellendus quidem debitis ab!
              Aliquid, facere.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa nam
              reiciendis unde magni eligendi excepturi maxime nulla adipisci,
              veniam officiis, explicabo commodi laboriosam blanditiis
              repellendus quidem debitis ab! Aliquid, facere. Lorem ipsum dolor
              sit amet, consectetur adipisicing elit. Ipsa nam reiciendis unde
              magni eligendi excepturi maxime nulla adipisci, veniam officiis,
              explicabo commodi laboriosam blanditiis repellendus quidem debitis
              ab! Aliquid, facere. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Ipsa nam reiciendis unde magni eligendi
              excepturi maxime nulla adipisci, veniam officiis, explicabo
              commodi laboriosam blanditiis repellendus quidem debitis ab!
              Aliquid, facere. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Ipsa nam reiciendis unde magni eligendi
              excepturi maxime nulla adipisci, veniam officiis, explicabo
              commodi laboriosam blanditiis repellendus quidem debitis ab!
              Aliquid, facere.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Sticky;
