import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <div>
      <div className="max-w-screen px-4 py-16 bg-black">
        <div className="grid md:grid-cols-3 md:gap-32 gap-y-10 w-[80vw] mx-auto">
          <div className="grid md:grid-cols-4 md:gap-32 gap-y-10 col-span-2 ">
            <div>
              <p className="font-medium text-white">Company</p>

              <ul className="mt-6 space-y-4 text-sm text-white">
                <li>
                  <a href="!#">lorem</a>
                </li>
                <li>
                  <a href="!#">lorem</a>
                </li>
                <li>
                  <a href="!#">lorem</a>
                </li>
                <li>
                  <a href="!#">lorem</a>
                </li>
              </ul>
            </div>

            <div>
              <p className="font-medium text-white">Franchises</p>

              <ul className="mt-6 space-y-4 text-sm text-white">
                <li>
                  <a href="!#">lorem</a>
                </li>

                <li>
                  <a href="!#">lorem</a>
                </li>

                <li>
                  <a href="!#">lorem</a>
                </li>

                <li>
                  <a href="!#">lorem</a>
                </li>
              </ul>
            </div>

            <div>
              <p className="font-medium text-white">Contact</p>

              <ul className="mt-6 space-y-4 text-sm text-white">
                <li>
                  <a href="!#">lorem</a>
                </li>

                <li>
                  <a href="!#">lorem</a>
                </li>

                <li>
                  <a href="!#">lorem</a>
                </li>

                <li>
                  <a href="!#">lorem</a>
                </li>
              </ul>
            </div>

            <div>
              <p className="font-medium text-white">Careers</p>

              <ul className="mt-6 space-y-4 text-sm text-white">
                <li>
                  <a href="!#">lorem</a>
                </li>

                <li>
                  <a href="!#">lorem</a>
                </li>

                <li>
                  <a href="!#">lorem</a>
                </li>

                <li>
                  <a href="!#">lorem</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="text-white">
            <img
              className="inline h-12 pr-3"
              src={require("../../assets/images/logoFull.png")}
              alt="foodcore logo"
            />

            <p className="mt-4 ">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse non
              cupiditate quae nam molestias.
            </p>

            <ul className="mt-8 flex gap-4 text-2xl">
              <li>
                <FaFacebook />
              </li>
              <li>
                <FaInstagram />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className="md:text-md text-xs text-white p-5 text-center"
        style={{ backgroundColor: "#000D1D" }}
      >
        <p>Copyright © Octacore Solutions Pvt. Ltd., All Rights Reserved</p>
      </div>
    </div>
  );
};

export default Footer;
