import React from "react";

const NotFound = () => {
  return (
    <div className="h-screen flex items-center justify-center bg-[#020f1f]">
      <div className="text-center text-white">
        <h1 className="text-9xl text-[#FBB950]">404</h1>
        {/* <h4 className="text-2xl font-semibold py-2">Page Not Found</h4> */}
        <p className="pb-5">This page doesn't seem to exist.</p>
        <button className="bg-transparent text-white py-1 px-5 border border-white hover:bg-white hover:text-black rounded-xl transition duration-500 ease-in-out">
          <a href="/">Back to Home</a>
        </button>
      </div>
    </div>
  );
};

export default NotFound;
