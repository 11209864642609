import React from "react";
import "./playground.css";
import { GiKnifeFork } from "react-icons/gi";

const Playground = () => {
  return (
    <div className="play">
      <div className="py-10">
        <button type="button" className="button">
          <span className="button__icon">
            <GiKnifeFork className="svg" />
          </span>
          <span className="button__text">Add Item</span>
        </button>
      </div>

      <div className="py-10 bg-black grid place-content-center">
        <button
          type="button"
          className="group rounded-xl bg-[#FBF0DF] hover:bg-transparent border-2 hover:border-[FBF0DF] py-3 text-black hover:text-white flex hover:gap-x-2 items-center justify-center h-12 w-44"
        >
          <GiKnifeFork className="text-[25px]" />
          <span className="text-[0px] opacity-0 invisible group-hover:text-[15px] group-hover:opacity-100 group-hover:visible transition-all duration-300 ease-in-out">
            Book Now
          </span>
        </button>
      </div>
    </div>
  );
};

export default Playground;
