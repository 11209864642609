import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useRef } from "react";

import Home from "../pages/home";
import Menu from "../pages/menu";
import Outlet from "../pages/outlet";

// for testing
import Playground from "../pages/playground/playground";

import NotFound from "../pages/notFound";

import ToTop from "./scrollToTop";
import Sticky from "../components/sticky";
import Navbar from "../components/general/nav";
import Footer from "../components/general/footer";

const Routing = () => {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const franchise1Ref = useRef(null);

  const MainLayout = ({ children }) => {
    return (
      <>
        <Navbar
          homeRef={homeRef}
          aboutRef={aboutRef}
          franchise1Ref={franchise1Ref}
        />
        <ToTop />
        {children}
        <Footer />
      </>
    );
  };

  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <MainLayout>
              <Home
                homeRef={homeRef}
                aboutRef={aboutRef}
                franchise1Ref={franchise1Ref}
              />
            </MainLayout>
          }
        />
        <Route
          exact
          path="/outlets"
          element={
            <MainLayout>
              <Outlet />
            </MainLayout>
          }
        />
        <Route
          exact
          path="/menu"
          element={
            <MainLayout>
              <Menu />
            </MainLayout>
          }
        />
        <Route exact path="/playground" element={<Playground />} />
        <Route exact path="/sticky" element={<Sticky />} />

        {/* default routes for invalid links */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default Routing;
