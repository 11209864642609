import React from "react";

const MenuOption = ({ categories }) => {
  return (
    <div className="md:grid grid-cols-4 gap-16 pt-16 place-items-center md:space-y-0 space-y-12 pb-32">
      {categories.map((item) => (
        <div
          className="p-5  border border-white text-white "
          style={{ borderBlockColor: "#FBF0DF", color: "#FBF0DF" }}
        >
          <img src={item.img} className="" alt="abc" />
          <div className="mt-2">
            <h4 className="text-[20px] font-bold">{item.title}</h4>
            <p className="mt-2 md:text-[20px] text-[15px]">{item.description}</p>
            <p className="md:text-[20px] text-[15px]">{item.price}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MenuOption;
