import React from "react";
import { Link } from "react-router-dom";

const Best = () => {
  let data = {
    img: require("../../assets/images/best.png"),
    title: " Why we are the best?",
    description:
      " Our outlets stand out for their quality, diversity, exceptional customer service, innovation, community engagement, and overall exceptional experience, making them a top choice for many customers.",
  };

  return (
    <div>
      <div
        className="md:grid grid-cols-2"
        style={{ backgroundColor: "#020f1f" }}
      >
        <div className="flex items-center justify-center md:pb-0 pb-6">
          <img src={data.img} alt="best" />
        </div>

        <div
          className="flex flex-col justify-center  md:w-[40vw] w-[80vw] mx-auto"
          style={{ color: "#FBF0DF" }}
        >
          <h2 className="md:text-4xl text-3xl font-bold pb-2">{data.title}</h2>
          <p className="md:text-md text-sm font-light text-justify">
            {data.description}
          </p>
          <div className="pt-6 md:pb-0 pb-8">
            <Link to="/menu">
              <button class="bg-transparent text-white md:text-md text-sm  py-1 px-5 border border-white hover:bg-white hover:text-black rounded-xl transition duration-500 ease-in-out">
                View our menu
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Best;
