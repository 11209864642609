import React, { useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Reservation from "../../pages/reservation";

function HomePage({ homeRef }) {
  let data = {
    img: require("../../assets/images/homepage.png"),
    title: "Exquisite Culinary",
    description:
      "A tantalizing fusion of flavors and artfully prepared dishes for unparalleled dining experiences.",
  };

  let events = [
    // {
    //   id: 1,
    //   media: require("../../assets/video/Raindrops_Videvo.mp4"),
    //   media_type: "video",
    //   name: "Demo",
    //   date: "123",
    // },
  ];

  // for pop up
  const MySwal = withReactContent(Swal);

  const ReserationPopUp = withReactContent(Swal);

  const showReservationPopup = () => {
    ReserationPopUp.fire({
      title: "Book a Table",
      html: <Reservation />,
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  useEffect(() => {
    if (events !== null && events.length > 0) {
      MySwal.fire({
        html: (
          <>
            {/* {events ? ( */}
            <>
              {events.map((event) => (
                <div key={event.id}>
                  {event.media_type === "image" ? (
                    <img
                      className="pt-5"
                      src={event.media}
                      alt={`${event.name} on ${event.date}`}
                    />
                  ) : event.media_type === "video" ? (
                    <video
                      src={event.media}
                      alt={`${event.name} on ${event.date}`}
                      muted
                      autoPlay={true}
                      loop
                    />
                  ) : (
                    <p>Media not supported.</p>
                  )}

                  {/* for video */}
                </div>
              ))}
            </>
            {/* // ) : events_error ? (
            //   <p className="pt-32 text-center">{events_error}</p>
            // ) : (
            //   <p className="pt-32 text-center">
            //     <Loader />
            //   </p>
            )} */}
          </>
        ),
        showConfirmButton: false,
        showCloseButton: true,
        width: 1000,
      });
    }
  }, [events, MySwal]);

  return (
    <div ref={homeRef}>
      <div className="md:grid grid-cols-3 bg-[#070707] md:pt-0 pt-28">
        <div className="flex flex-col justify-center text-white xl:ml-32 lg:mx-5 mx-4 ">
          <h1
            className="md:text-6xl text-5xl mb-4"
            style={{ color: "#FBB950" }}
          >
            {data.title}
          </h1>
          <p className="md:text-md text-sm">{data.description}</p>
          <div className="pt-6">
            <button
              onClick={() => {
                showReservationPopup();
              }}
              className="bg-transparent text-white py-1 px-5 border border-white hover:bg-white hover:text-black rounded-xl transition duration-500 ease-in-out"
            >
              Book Now
            </button>
          </div>
        </div>

        <div className="home-img-wrapper col-span-2 md:pt-0 pt-6">
          <img
            className="md:h-[80vh] w-full object-cover"
            src={data.img}
            alt="food"
          />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
