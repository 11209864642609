import React from "react";

const Priority = () => {
  let data = {
    img: require("../../assets/images/priority.png"),
    title: "Customers our Priority",
    description:
      "Ensuring customer satisfaction is our top priority. We are dedicated to meeting and exceeding the needs of every customer who walks through our doors. From providing high-quality products and services to delivering exceptional customer care, we prioritize creating a positive experience for each individual. Our commitment to understanding and addressing customer preferences, as well as actively seeking feedback, underscores our dedication to putting customers first. By consistently placing their needs at the forefront, we aim to build long-lasting relationships and exceed expectations.",
  };

  return (
    <div>
      <div
        className="md:grid grid-cols-2 gap-4 md:pb-0 pb-8"
        style={{ backgroundColor: "#020f1f" }}
      >
        <div className="priority-img-wrapper flex items-center justify-center ">
          <img
            className="md:h-[70vh] w-full object-cover"
            src={data.img}
            alt="priority"
          />
        </div>
        <div
          className="flex flex-col justify-center md:w-[40vw] w-[80vw] mx-auto"
          style={{ color: "#FBF0DF" }}
        >
          <h2 className="md:text-4xl text-3xl font-bold pb-4 md:pt-0 pt-6">
            {data.title}
          </h2>
          <p className="md:text-md text-sm text font-light text-justify">
            {data.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Priority;
