import React, { useState } from "react";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import MenuOption from "../components/menu/menuOption";

const Menu = () => {
  let categories = [
    {
      title: "Starters",
      menu: [
        {
          img: require("../assets/images/experience1.png"),
          title: "Starters Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Starters Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Starters Food Name",
          description: "lorem ipsum abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
      ],
    },
    {
      title: "Mains",
      menu: [
        {
          img: require("../assets/images/experience1.png"),
          title: "Mains Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Mains Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Mains Food Name",
          description: "lorem ipsum abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
      ],
    },
    {
      title: "Desserts",
      menu: [
        {
          img: require("../assets/images/experience1.png"),
          title: "Desserts Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Desserts Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Desserts Food Name",
          description: "lorem ipsum abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
      ],
    },
    {
      title: "Appetizer",
      menu: [
        {
          img: require("../assets/images/experience1.png"),
          title: "Appetizer Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Appetizer Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Appetizer Food Name",
          description: "lorem ipsum abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
      ],
    },
    {
      title: "Salad",
      menu: [
        {
          img: require("../assets/images/experience1.png"),
          title: "Salad Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Salad Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Salad Food Name",
          description: "lorem ipsum abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
        {
          img: require("../assets/images/experience1.png"),
          title: "Food Name",
          description: "lorem ipsum abc abc abc",
          price: "$10",
        },
      ],
    },
  ];

  const [title, setTitle] = useState("Starters");
  const [menu, setMenu] = useState(categories[0].menu);

  const handleButtonClick = (item) => {
    setTitle(item.title);
    setMenu(item.menu);
  };

  return (
    <div style={{ backgroundColor: "#0A1626" }}>
      <div className="max-w-screen-2xl mx-auto px-7">
        <h2
          className="md:text-7xl text-4xl text-center pt-32 font-bold mb-8"
          style={{ color: "#FBF0DF" }}
        >
          {title}
        </h2>

        <Link to="/outlets">
          <button className="text-white md:text-5xl text-3xl cursor-pointer absolute md:right-40 right-12 md:top-36 top-32">
            <IoArrowBackCircleOutline />
          </button>
        </Link>
        <div className="md:flex flex-row justify-center md:space-x-16 space-x-4 md:space-y-0 space-y-6">
          {categories.map((item) => (
            <button
              onClick={() => handleButtonClick(item)}
              type="button"
              className="text-white border border-gray-300 rounded-lg text-sm px-5 py-1.5 me-2 hover:bg-white hover:text-black"
            >
              {item.title}
            </button>
          ))}
        </div>

        <MenuOption categories={menu} />
      </div>
    </div>
  );
};

export default Menu;
