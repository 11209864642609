import React from 'react'
import { IoFastFoodOutline } from "react-icons/io5";
import { MdOutlineLight } from "react-icons/md";
import { PiCookingPot } from "react-icons/pi";

const Fresh = () => {

  let data = [
    {
      icon: <IoFastFoodOutline />,
      heading: "Fresh Food",
      subHeading: "Fresh food is natural and healthy",
    },
    {
      icon: <MdOutlineLight />,
      heading: "Ambiance",
      subHeading: "Elevate your experience",
    },
    {
      icon: <PiCookingPot />,
      heading: "Unique Recipes",
      subHeading: "Creative culinary creations",
    },
  ];

  return (
    <div
      className="md:grid grid-cols-3 justify-items-center md:space-y-0 space-y-10 p-16"
      style={{ backgroundColor: "#1E5973" }}
    >
      {data.map((item) => (
        <div className="flex items-center gap-3 text-left text-white">
          <div className="text-4xl">{item.icon}</div>
          <div>
            <h2 className="md:text-2xl text-xl font-bold">{item.heading}</h2>
            <p className="md:text-md text-sm">{item.subHeading}</p>
          </div>
        </div>
      ))}
    </div>

  )
}

export default Fresh;