import React, { useState } from "react";


// notification

const Reservation = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [outlet, setOutlet] = useState("");
  const [noOfPax, setNoOfPax] = useState(0);
  const [noOfChildren, setNoOfChildren] = useState(0);
  const [dateOfArrival, setDateOfArrival] = useState("");
  const [timeOfArrival, setTimeOfArrival] = useState("");
  const [specialRequests, setSpecialRequests] = useState("");
  const [others, setOthers] = useState("");


  return (
    <form className="md:px-8 px-3">
      {/* FName | LName */}
      <div className="mt-6 grid md:grid-cols-2 md:gap-6">
        <div className="group relative z-0 mb-6 w-full">
          <input
            type="text"
            name="first_name"
            id="first_name"
            className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            placeholder=" "
            required
          />
          <label
            htmlFor="first_name"
            className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0  peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
          >
            First name <span className="text-rose-500">*</span>
          </label>
        </div>
        <div className="group relative z-0 mb-6 w-full">
          <input
            type="text"
            name="last_name"
            id="last_name"
            className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder=" "
            required
          />
          <label
            htmlFor="last_name"
            className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
          >
            Last name <span className="text-rose-500">*</span>
          </label>
        </div>
      </div>

      {/* Contact Number */}
      <div className="group relative z-0 mb-6 w-full">
        <input
          type="number"
          pattern="[0-9]{10}"
          name="phone"
          id="phone"
          className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
          value={contactNumber}
          onChange={(e) => setContactNumber(e.target.value)}
          placeholder=" "
          required
        />
        <label
          htmlFor="phone"
          className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
        >
          Contact Number (98xxxxxxxx) <span className="text-rose-500">*</span>
        </label>
      </div>

      {/* Number of people |  */}
      <div className="grid md:grid-cols-2 md:gap-6">
        <div className="group relative z-0 mb-6 w-full">
          <input
            type="number"
            name="people"
            id="people"
            min="1"
            className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
            value={noOfPax}
            onChange={(e) => setNoOfPax(e.target.value)}
            placeholder=" "
            required
          />
          <label
            htmlFor="people"
            className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
          >
            No of Pax <span className="text-rose-500">*</span>
          </label>
        </div>
        <div className="group relative z-0 mb-6 w-full">
          <input
            type="number"
            name="children"
            id="children"
            min="0"
            className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
            value={noOfChildren}
            onChange={(e) => setNoOfChildren(e.target.value)}
            placeholder=" "
          />
          <label
            htmlFor="children"
            className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
          >
            No of Children
          </label>
        </div>
      </div>

      {/* Date of Arrival | Time */}
      <div className="grid md:grid-cols-2 md:gap-6">
        <div className="relative mb-6 max-w-sm">
          <input
            datepicker="true"
            type="date"
            id="date"
            value={dateOfArrival}
            onChange={(e) => setDateOfArrival(e.target.value)}
            className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
            required
          />
          <label
            htmlFor="date"
            className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
          >
            Date of Arrival <span className="text-rose-500">*</span>
          </label>
        </div>
        <div className="group relative z-0 mb-6 w-full">
          <div
            className="relative"
            data-te-timepicker-init
            data-te-input-wrapper-init
          >
            <input
              type="time"
              className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
              value={timeOfArrival}
              onChange={(e) => setTimeOfArrival(e.target.value)}
              id="time"
              required
            />
            <label
              htmlFor="time"
              className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
            >
              Select a time <span className="text-rose-500">*</span>
            </label>
          </div>
        </div>
      </div>

      {/* Special Requests */}
      <div className="relative mb-3" data-te-input-wrapper-init>
        <textarea
          className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
          rows="3"
          id="specialRequests"
          value={specialRequests}
          onChange={(e) => setSpecialRequests(e.target.value)}
          placeholder=" "
        ></textarea>
        <label
          htmlFor="specialRequests"
          className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
        >
          Special Requests
        </label>
      </div>

      {/* Others */}
      <div className="relative mb-3" data-te-input-wrapper-init>
        <textarea
          className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
          rows="3"
          id="specialRequests"
          value={others}
          onChange={(e) => setOthers(e.target.value)}
          placeholder=" "
        ></textarea>
        <label
          htmlFor="specialRequests"
          className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
        >
          Others
        </label>
      </div>

      <div className="pt-6">
            <button class="bg-transparent text-black py-1 px-5 border border-black hover:bg-black hover:text-white rounded-xl transition duration-500 ease-in-out">
              Book Now
            </button>
            </div>
    </form>
  );
};

export default Reservation;
