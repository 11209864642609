import React, { useContext } from "react";
import { MenuContext } from "react-flexible-sliding-menu";
import { RxCross1 } from "react-icons/rx";

function NavMenu() {
  const { closeMenu } = useContext(MenuContext);
  const pathname = window.location.pathname;

  const scrollToTop = () => {
    closeMenu();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const scrollToAbout = () => {
    closeMenu();
    window.scrollTo({
      top: 875,
      left: 0,
      behavior: "smooth",
    });
  };

  const scrollToFranchise1 = () => {
    closeMenu();
    window.scrollTo({
      top: 1475,
      left: 0,
      behavior: "smooth",
    });
  };

  const navItems = [
    {
      label: "Home",
      path: "/",
      scroll: scrollToTop,
    },
    {
      label: "About us",
      path: "/",
      scroll: scrollToAbout,
    },
    {
      label: "Franchises",
      path: "/",
      scroll: scrollToFranchise1,
    },
    {
      label: "Outlets",
      path: "/outlets",
    },
    // {
    //   label: "Contact",
    //   path: "/",
    // },
  ];

  return (
    <div>
      <div className="mx-auto flex items-center justify-between">
        <div className="h-16 w-72 p-4 text-xl">
          <a href="/">
            <img
              className="inline h-12 pr-3"
              src={require("../../assets/images/logo.png")}
              alt="foodcore logo"
            />
          </a>
        </div>
        <button onClick={closeMenu} className="text-2xl mr-8">
          <RxCross1 />
        </button>
      </div>

      <div className="flex flex-col space-y-2 w-[80vw] mx-auto pt-6">
        {navItems.map((item, index) => (
          <div key={index}>
            {pathname === "/" && item.scroll ? (
              <p
                onClick={item.scroll}
                className="cursor-pointer text-[30px] pb-5 "
              >
                {item.label}
              </p>
            ) : (
              <a
                href={item.path}
                className="cursor-pointer text-[30px] pb-5 block"
              >
                {item.label}
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default NavMenu;
