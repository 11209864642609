import React from "react";
import { CiLocationOn } from "react-icons/ci";
import { IoIosTimer } from "react-icons/io";
import { MdEditNote } from "react-icons/md";

const Locate = () => {
  let data = [
    {
      icon: <CiLocationOn />,
      heading: "Locate Us",
      subHeading: "Riverside 25, San Francisco, California",
    },
    {
      icon: <IoIosTimer />,
      heading: "Open Hours",
      subHeading: "Mon To Fri 9:00 AM - 9:00 PM",
    },
    {
      icon: <MdEditNote />,
      heading: "Reservation",
      subHeading: "hirestaurantate@gmail.com",
    },
  ];

  return (
    <div
      className="md:grid grid-cols-3 justify-items-center md:space-y-0 space-y-10 p-16"
      style={{ backgroundColor: "#1E5973" }}
    >
      {data.map((item) => (
        <div className="flex items-center gap-2 text-left text-white">
          <div className="text-4xl">{item.icon}</div>
          <div>
            <h2 className="md:text-2xl text-xl font-bold">{item.heading}</h2>
            <p className="md:text-md text-sm">{item.subHeading}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Locate;
