import React from "react";

const About = ({ aboutRef }) => {
  let data = {
    img: require("../../assets/images/about.png"),
    title: "About Us",
    description:
      "Exquisite Culinary is a haven for discerning food enthusiasts, offering an extraordinary dining experience that transcends the ordinary. Our masterful chefs meticulously craft each dish, using only the finest, freshest ingredients to create a symphony of flavors. The ambiance is designed to complement the culinary artistry, ensuring that every visit is a celebration of exceptional taste, creativity, and hospitality. Whether for a casual meal or a special occasion, Exquisite Culinary promises an unforgettable gastronomic adventure.",
  };

  return (
    <div ref={aboutRef}>
      <div
        className="md:grid grid-cols-2"
        style={{ backgroundColor: "#020F1F" }}
      >
        <div className="about-img-wrapper flex items-center justify-center md:pb-0 pb-6">
          <img className="md:h-[80vh] w-full object-cover" src={data.img} alt="about" />
        </div>

        <div className="flex flex-col justify-center text-white md:w-[40vw] w-[80vw] mx-auto md:pb-0 pb-4">
          <h1 className="text-6xl" style={{ color: "#FBB950" }}>
            {data.title}
          </h1>
          <p className="md:text-md text-sm text font-light text-justify">
            {data.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
