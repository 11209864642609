import React from "react";
import "../../styles/sticky.css";

const Franchise1 = ({ franchise1Ref }) => {
  let data = [
    {
      img: require("../../assets/images/franchise1.png"),
      title: "Lorem Ipsum",
      description:
        "Lorem ipsum dolor sit amet consectetur. Proin lectus eu semper ut bibendum id phasellus rhoncus pretium.",
    },
    {
      img: require("../../assets/images/franchise1.png"),
      title: "Lorem Ipsum",
      description:
        "Lorem ipsum dolor sit amet consectetur. Proin lectus eu semper ut bibendum id phasellus rhoncus pretium.",
    },
    {
      img: require("../../assets/images/franchise1.png"),
      title: "Lorem Ipsum",
      description:
        "Lorem ipsum dolor sit amet consectetur. Proin lectus eu semper ut bibendum id phasellus rhoncus pretium.",
    },
    {
      img: require("../../assets/images/franchise1.png"),
      title: "Lorem Ipsum",
      description:
        "Lorem ipsum dolor sit amet consectetur. Proin lectus eu semper ut bibendum id phasellus rhoncus pretium.",
    },
  ];

  return (
    <div ref={franchise1Ref} style={{ backgroundColor: "#020F1F" }}>
      <section className="sticky-section">
        <div className="right">
          <div className="inner">
            <div className="flex flex-col text-white md:w-[40vw] w-[75vw] mx-auto pb-10">
              <h1
                className="text-7xl mb-4 pt-6 text-center"
                style={{ color: "#FBB950" }}
              >
                Franchise 1
              </h1>
              <p className="text-center">
                Lorem ipsum dolor sit amet consectetur. Proin lectus eu semper
                ut bibendum id phasellus rhoncus pretium. Tempus nulla interdum
                a posuere nunc nunc sagittis orci nunc. Sem.
              </p>
            </div>
            <div className="flex flex-col gap-9 items-center">
              {data.map((item) => (
                <div className="relative grid md:h-[17rem] w-full flex-col justify-center overflow-hidden rounded-xl text-center">
                  <img
                    className="absolute blur-sm brightness-50"
                    src={item.img}
                    alt="abc"
                  />
                  <div
                    className="relative grid place-content-end p-10"
                    style={{ color: "#FED0BB" }}
                  >
                    <h2 className="mb-2 block md:text-2xl text-xl font-bold leading-[1.5]  ">
                      {item.title}
                    </h2>
                    <p className="md:text-md text-sm">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div class="left">
          <div class="inner sticky">
            <img
              className="max-h-screen bg object-cover"
              src={require("../../assets/images/franchise1.png")}
              alt="franchise"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Franchise1;
