import React, { useContext, useState } from "react";
import { MenuContext } from "react-flexible-sliding-menu";
import { GiKnifeFork } from "react-icons/gi";
import { IoMenuOutline } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Reservation from "../../pages/reservation";

const Navbar = ({ homeRef, aboutRef, franchise1Ref }) => {
  const { toggleMenu } = useContext(MenuContext);
  const { pathname } = useLocation();

  const scrollToTop = () => {
    homeRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToAbout = () => {
    aboutRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToFranchise1 = () => {
    franchise1Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const navItems = [
    {
      label: "Home",
      path: "/",
      scroll: scrollToTop,
    },
    {
      label: "About us",
      path: "/",
      scroll: scrollToAbout,
    },
    {
      label: "Franchises",
      path: "/",
      scroll: scrollToFranchise1,
    },
    {
      label: "Outlets",
      path: "/outlets",
    },
    // {
    //   label: "Contact",
    //   path: "/",
    // },
  ];

  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 10) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  const ReserationPopUp = withReactContent(Swal);

  const showReservationPopup = () => {
    ReserationPopUp.fire({
      title: "Book a Table",
      html: <Reservation />,
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  return (
    <nav
      className={`fixed w-screen bg-transparent-600 xl:px-32 lg:px-5 px-4 md:p-5 p-4 z-20 
      ${color ? "shadow-lg bg-[#232323]" : ""}`}
    >
      <div className="mx-auto flex items-center justify-between mr-6">
        <a className="text-xl text-white flex items-center " href="/">
          <img
            className="inline h-12 pr-3"
            src={` ${
              color
                ? require("../../assets/images/logoWhite.png")
                : require("../../assets/images/logo.png")
            }`}
            alt="foodcore logo"
          />
          {/* <h3 className="inline font-['norland'] tracking-widest text-red-100 text-4xl">
            Foodcore
          </h3> */}
        </a>

        <div className="hidden lg:block text-white">
          <ul className="flex items-center space-x-10">
            {navItems.map((item, index) => (
              <div key={index}>
                {pathname === "/" && item.scroll ? (
                  <p
                    onClick={item.scroll}
                    className={`cursor-pointer text-[15px] inline text-white`}
                  >
                    {item.label}
                  </p>
                ) : (
                  <a
                    href={item.path}
                    className={`cursor-pointer text-[15px] inline text-white`}
                  >
                    {item.label}
                  </a>
                )}
              </div>
            ))}
            <button
              type="button"
              className="group rounded-xl bg-[#FBF0DF] hover:bg-transparent border-2 hover:border-[FBF0DF] py-3 text-black hover:text-white flex hover:gap-x-2 items-center justify-center h-10 w-40"
              onClick={() => {
                showReservationPopup();
              }}
            >
              <GiKnifeFork className="text-[25px]" />
              <span className="text-[0px] opacity-0 invisible group-hover:text-[15px] group-hover:opacity-100 group-hover:visible transition-all duration-300 ease-in-out">
                Book Now
              </span>
            </button>
          </ul>
        </div>

        <button
          onClick={toggleMenu}
          className={`text-4xl block lg:hidden text-white`}
        >
          <IoMenuOutline />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
