import { React } from "react";
import Locate from "../components/outlets/locate";
import Best from "../components/outlets/best";
import Experience from "../components/outlets/experience";
import Services from "../components/outlets/services";
import Priority from "../components/outlets/priority";

const Outlet = () => {
  let data = {
    img: require("../assets/images/outlets.png"),
    title: "Our Outlets",
    description:
      "Customers can expect a great selection of items, excellent customer service, and a well-curated overall experience. Cater to various tastes, locations and preferences, ensuring that each visitor finds something that resonates with them.",
  };

  return (
    <div>
      <div
        className="md:grid grid-cols-2 gap-4 md:pt-0 pt-28 "
        style={{ backgroundColor: "#070707" }}
      >
        <div className="md:flex flex-col justify-center text-white md:w-[40vw] w-[80vw] mx-auto md:pb-0 pb-8">
          <h1 className="md:text-6xl text-5xl" style={{ color: "#FBB950" }}>
            {data.title}
          </h1>
          <p
            className="md:text-lg text-sm text font-light text-justify"
            style={{ color: "#FBF0DF" }}
          >
            {data.description}
          </p>

          <div></div>
        </div>

        <div className="aboutPage-img-wrapper">
          <img
            className="md:h-[85vh] w-full object-cover"
            src={data.img}
            alt="outlet"
          />
        </div>
      </div>
      <Locate />
      <Best />
      <Experience />
      <Services />
      <Priority />
    </div>
  );
};

export default Outlet;
