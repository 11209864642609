import React from "react";

const Experience = () => {
  let data = {
    img1: require("../../assets/images/experience2.png"),
    img2: require("../../assets/images/experience2.png"),
    title: "It's an experience!",
    description:
      "{   } Offers more than just food it's an immersive experience. The ambiance, service, culinary creativity, and social interaction combine to create memorable moments. With unique themes and live entertainment, dining out engages all the senses, making it a place for meaningful social interaction and lasting memories.",
  };

  return (
    <div>
      <div
        className="md:grid grid-cols-2"
        style={{ backgroundColor: "#020f1f" }}
      >
        <div
          className="flex flex-col justify-center  md:w-[40vw] w-[80vw] mx-auto"
          style={{ color: "#FBF0DF" }}
        >
          <h2 className="md:text-4xl text-3xl font-bold md:pt-0 pt-6 pb-2">
            {data.title}
          </h2>
          <p className="md:text-md text-sm font-light text-justify">
            {data.description}
          </p>
        </div>

        <div className="md:grid grid-cols-2 gap-10 md:pt-0 pt-6">
          <div>
            <img
              className="md:h-[50vh] h-[40vh] w-full object-cover"
              src={data.img1}
              alt="experience"
            />
          </div>

          <div>
            <img
              className="md:h-[50vh] h-[40vh] w-full object-cover"
              src={data.img2}
              alt="experience"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
