import React from "react";
import "../../styles/scroll.css";
import { PiBowlFoodLight } from "react-icons/pi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Services = () => {
  let data = [
    {
      img: require("../../assets/images/services.png"),
      title: "Lorem",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      img: require("../../assets/images/services.png"),
      title: "Lorem",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      img: require("../../assets/images/services.png"),
      title: "Lorem",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      img: require("../../assets/images/services.png"),
      title: "Lorem",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      img: require("../../assets/images/services.png"),
      title: "Lorem",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div style={{ backgroundColor: "#1E5973" }}>
      <div>
        <img
          className="absolute max-w-32"
          src={require("../../assets/images/servicesicon1.png")}
          alt="services"
        />

        <img
          className="absolute max-w-32 -right-0"
          src={require("../../assets/images/servicesicon2.png")}
          alt="services"
        />
      </div>
      <div className="text-center md:text-7xl text-5xl text-white pt-10 pb-10">
        <h1>Our Services</h1>
      </div>

      <div className="justify-center md:space-y-0 space-y-12 pb-16">
        <Slider {...settings}>
          {data.map((item) => (
            <>
              <div class="relative group mx-8 cursor-pointer group overflow-hidden text-gray-50 h-72 rounded-2xl hover:duration-700 duration-700">
                <div class="h-72 bg-black text-gray-800">
                  <div class="absolute bg-[#B7E6E8] -top-36 p-8 flex flex-col group-hover:-top-0 group-hover:duration-600 duration-500 text-center">
                    <span class="text-black font-bold ">{item.title}</span>
                    <p class=" text-black">{item.description}</p>
                  </div>
                  <div
                    className="h-72 flex flex-col items-center place-content-center"
                    style={{ backgroundColor: "#020F1F" }}
                  >
                    <div className="text-white text-6xl ">
                      <PiBowlFoodLight />
                    </div>
                    <p className="text-white text-xl">Hover Me</p>
                  </div>
                </div>
              </div>
            </>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Services;
