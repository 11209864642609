import React from "react";

import HomePage from "../components/home/homePage";
import Fresh from "../components/home/fresh";
import About from "../components/home/about";
import Franchise2 from "../components/home/franchise2";
import Franchise1 from "../components/home/franchise1";

const Home = ({ homeRef, aboutRef, franchise1Ref }) => {
  return (
    <div>
      <HomePage homeRef={homeRef} />
      <Fresh />
      <About aboutRef={aboutRef} />
      <Franchise1 franchise1Ref={franchise1Ref} />
      <Franchise2 />
    </div>
  );
};

export default Home;
