import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import NavMenu from "./components/general/navMenu";
import MenuProvider from "react-flexible-sliding-menu";
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MenuProvider MenuComponent={NavMenu} direction="right" width="100vw">
			<App />
		</MenuProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
